import React from "react";
// @material-ui/core components
import { makeStyles } from "@mui/styles";
// Lottie
import Lottie from 'react-lottie';
import * as handeWaveData from '../../public/lotties/hand-wave.json';
// Stylesheet
import styles from "styles/jss/nextjs-material-kit-pro/components/presentationSlideStyle.js";
const useStyles = makeStyles(styles);

export default function PresentationSlide(props) {
    const classes = useStyles();
    
    const renderHandWave = () => {
        const defaultOptions = {
            loop: false,
            autoplay: true, 
            animationData: handeWaveData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <div style={{ margin: "-20px 0px -20px 20px" }}>
                <Lottie
                    options={defaultOptions}
                    width={50}
                />
            </div>
        );
    }

    return (
        <div className={classes.slideContainer}>
            <div className={classes.presentationCurveContainer}>
                <svg
                    height={"100%"}
                    width={"100%"}
                    style={{ overflow: 'visible', position: 'relative', top: '-98%', right: '-40%' }}
                >
                    <circle cx={"80%"} cy={"80%"} r={"80%"} className={classes.presentationCurve}/>
                </svg>
                <svg
                    height={"100%"}
                    width={"100%"}
                    style={{ overflow: 'visible', position: 'relative', bottom: '78%', right: '-60%' }}
                >
                    <circle cx={"90%"} cy={"90%"} r={"90%"} className={classes.presentationCurve}/>
                </svg>
            </div>
            <div className={classes.presentationTitle}>
                <span>Hey there</span>
                {renderHandWave()}
            </div>
        </div>
    );
}