/*eslint-disable*/
import React from "react";
// Framer motion
import { motion, AnimatePresence } from "framer-motion";
// @material-ui/core components
import makeStyles from "@mui/styles/makeStyles";
import Checkbox from "@mui/material/Checkbox";
import Button from '@mui/material/Button';
import CircularProgress from "@mui/material/CircularProgress";

import { secondaryColor } from "styles/jss/nextjs-material-kit-pro";
import signupPageStyle from "styles/jss/nextjs-material-kit-pro/pages/signupPageStyle.js";

const useStyles = makeStyles(signupPageStyle);

const containerVariants = {
    hidden: { opacity: 0 },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.3,
            ease: [0.455, 0.03, 0.515, 0.955]
        }
    }
}

const childVariants = {
    hidden: { opacity: 0, y: -30 },
    show: { opacity: 1, y: 0 }
}

export default function AddonsPage(props) {
    const classes = useStyles();
    const getConsultantTitle = () => {
        switch(props.formState.businessAccountTypeId) {
            case 1:
                return "App & Booking";
            case 2:
                return "Booking";
            case 3:
                return "App";
            default:
                return null;
        }
    };
    const getAddonPrice = (business_account_addon_id) => {
        if(props.formState?.addonData) {
            let foundAddonObj = props.formState.addonData.find(x => x.business_account_addon_id === business_account_addon_id);
            if(foundAddonObj.priceData.length > 1) {
                let priceObj = foundAddonObj.priceData.find(x => x.business_account_type_id === props.formState.businessAccountTypeId);
                return '+ £' + Number(priceObj.amount / 100);
            } else if(foundAddonObj.priceData.length === 1) {
                return '+ £' + Number(foundAddonObj.priceData[0].amount / 100);
            } else {
                return null;
            }
        } else {
            return <CircularProgress color={"primary"} size={15.5}/>
        }
    };
    const isAddonEnabled = (businessAccountAddonId) => {
        if(props.formState?.addonData) {
            let foundAddonObj = props.formState.addonData.find(x => x.business_account_addon_id === businessAccountAddonId);
            if(foundAddonObj) {
                return foundAddonObj.active;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };
    const getDeliveryMetadata = (deliveryType) => {
        let addonObj = props.formState?.addonData?.find(x => x.business_account_addon_id === 2);
        if(!addonObj) {
            return "--";
        }
        if(deliveryType === "express") {
            return addonObj.metadata?.express_delivery_time;
        } else {
            return addonObj.metadata?.standard_delivery_time;
        }
    };
    const handleAddonChange = (businessAccountAddonId) => {
        let businessAccountAddonIds = [...props.formState.businessAccountAddonIds];
        if(businessAccountAddonIds.includes(businessAccountAddonId)) {
            businessAccountAddonIds = businessAccountAddonIds.filter(x => x !== businessAccountAddonId);
        } else {
            businessAccountAddonIds.push(businessAccountAddonId);
        }
        props.handleChange('businessAccountAddonIds', businessAccountAddonIds);
    };
    return (
        <AnimatePresence>
            {props.isVisible && (
                <motion.div
                    className={classes.formSectionContainer}
                    variants={containerVariants}
                    initial="hidden"
                    animate="show"
                    exit={{ opacity: 0, y: 30, transition: { duration: 0.2 } }}
                >
                    <motion.div
                        className={classes.addonContainer}
                        variants={childVariants}
                    >
                        <div className={classes.addonBody}>
                            <span className={classes.addonTitle}>
                                {getConsultantTitle()} Consultant
                            </span>
                            <span>
                                Included <Checkbox disabled checked />
                            </span>
                        </div>
                        <p>
                            All the support you need with your own dedicated UK-based consultant. Your consultant will be on hand to help anytime. Whether it be during the setup process, or just to make sure you're making the best use of our tools.
                        </p>
                    </motion.div>
                    {[1,3].includes(props.formState.businessAccountTypeId) && (
                        <motion.div
                            className={classes.addonContainer}
                            variants={childVariants}
                        >
                            <div className={classes.addonBody}>
                                <span className={classes.addonTitle}>
                                    App Design
                                </span>
                                <span>
                                    Included <Checkbox disabled checked />
                                </span>
                            </div>
                            <p>
                                Send your logo, colours and brand guidelines to your consultant and we'll bring your app to life!
                            </p>
                        </motion.div>
                    )}
                    <motion.div
                        className={classes.addonContainer}
                        variants={childVariants}
                    >
                        <div className={classes.addonBody}>
                            <span className={classes.addonTitle}>
                                Data Onboarding
                            </span>
                            <span>
                                {getAddonPrice(1)}
                                <Checkbox
                                    color="secondary"
                                    disabled={!isAddonEnabled(1)}
                                    onChange={(e) => handleAddonChange(1)}
                                    checked={props.formState.businessAccountAddonIds.includes(1)}
                                />
                            </span>
                        </div>
                        {!isAddonEnabled(1) && (
                            <p className={classes.planOptionDescription} style={{ color: secondaryColor[0], fontWeight: '500' }}>
                                Currently unavailable due to high demand
                            </p>
                        )}
                        <p>
                            Really busy? Your consultant can onboard your business, service and staff information for you.
                        </p>
                    </motion.div>
                    {[1,3].includes(props.formState.businessAccountTypeId) && (
                        <motion.div
                            className={classes.addonContainer}
                            variants={childVariants}
                        >
                            <div className={classes.addonTitle}>App Setup</div>
                            <p>
                                In a rush to get your app live on the App Stores? With Express App Setup, we'll put your app in our priority queue for deployment.
                            </p>
                            <div className={classes.addonBody}>
                                <span className={classes.addonTitle} style={{ fontWeight: '500' }}>
                                    Standard
                                </span>
                                <span>
                                    Included <Checkbox color="secondary" checked disabled />
                                </span>
                            </div>
                            <p style={{ marginTop: '-0.8em', fontSize: '0.8em' }}>
                                Est. {getDeliveryMetadata('standard')}
                            </p>
                            <div className={classes.addonBody}>
                                <span className={classes.addonTitle} style={{ fontWeight: '500' }}>
                                    Express
                                </span>
                                <span>
                                    {getAddonPrice(2)}
                                    <Checkbox
                                        color="secondary"
                                        disabled={!isAddonEnabled(2)}
                                        onChange={(e) => handleAddonChange(2)}
                                        checked={props.formState.businessAccountAddonIds.includes(2)}
                                    />
                                </span>
                            </div>
                            <p style={{ marginTop: '-0.8em', fontSize: '0.8em' }}>
                                Est. {getDeliveryMetadata('express')}
                            </p>
                            {!isAddonEnabled(2) && (
                                <p
                                    className={classes.planOptionDescription}
                                    style={{
                                        color: secondaryColor[0],
                                        fontWeight: '500',
                                        marginBottom: 0,
                                        marginTop: '-0.8em',
                                        fontSize: '0.8em'
                                    }}
                                >
                                    Currently unavailable due to high demand
                                </p>
                            )}
                        </motion.div>
                    )}
                    <motion.div
                        variants={childVariants}
                        style={{ width: '100%' }}
                    >
                        <Button
                            variant="contained"
                            size="large"
                            fullWidth
                            className={classes.formButton}
                            onClick={() => props.changePage(3)}
                        >
                            Next
                        </Button>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}