import { loadStripe } from '@stripe/stripe-js/pure';

export const getStripe = () => {
    let stripePublishableKey;
    if(process.env.NODE_ENV === "production") {
        stripePublishableKey = "pk_live_51GxzB6FQ8tU2VmRuSkgfhmWioyfOQwEYnCX2TBrbKNYlKGD9YZkufezTf9Zy5CCTmxsOz1HwagrrmGPcxvlgPYiY00odjejsAJ";
    } else {
        stripePublishableKey = "pk_test_51GxzB6FQ8tU2VmRuTC3ufD9Vty8befyESFWsIsl9q5pyZEjl996fwL2bRx2wwLA1QCDfAHe5mo5dqNzidEkOGf9l00uBZXYCNp";
    }
    return loadStripe(stripePublishableKey);
}