import {
  primaryColor,
  secondaryColor,
  grayColor,
  containerFluid,
  cardTitle,
  whiteColor,
  blackColor,
  dangerColor,
  hexToRgb,
} from "styles/jss/nextjs-material-kit-pro.js";

const signupPageStyle = {
  pageContainer: {
    display: "flex",
    height: "100vh",
    height: "calc(var(--vh, 1vh) * 100)",
    overflow: "hidden",
    backgroundColor: "#FFFFFF"
  },
  formContainer: {
    flex: "1 1 0%",
    height: "100%",
    overflow: "auto"
  },
  formHeader: {
    display: "flex",
    flexDirection: "row"
  },
  formBody: {
    ...containerFluid,
    "@media (min-width: 576px)": {
      maxWidth: "540px",
    },
    "@media (min-width: 768px)": {
      maxWidth: "720px",
    },
    "@media (min-width: 992px)": {
      maxWidth: "960px",
    },
    "@media (min-width: 1200px)": {
      maxWidth: "500px",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  logoContainer: {
    width: "13em",
    marginTop: "2em",
    marginBottom: "1em"
  },
  formTitle: {
    position: "relative",
    fontWeight: "600",
    fontSize: "1.7em",
    marginBottom: "1em",
    color: primaryColor[0],
    width: "100%"
  },
  formProgressBarContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    gap: "2em",
    marginBottom: "2em"
  },
  formProgressBar: {
    borderRadius: '20px',
    backgroundColor: 'rgba(' + hexToRgb(primaryColor[0]) + ', 0.2)',
    height: '5px',
    '& .MuiLinearProgress-bar': {
        backgroundColor: secondaryColor[0],
        transition: 'transform .1s linear'
    }
  },
  formSectionContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  nameInputContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "1em",
    width: "100%"
  },
  formInput: {
    flex: 1,
    marginBottom: '1.5em'
  },
  termsContainer: {
    padding: '1em',
    marginBottom: '1.5em',
    backgroundColor: '#f9f9f9'
  },
  termsLabel: {
    color: primaryColor[2]
  },
  termsLink: {
    '&:hover': {
      color: secondaryColor[0]
    }
  },
  slideContainer: {
    //backgroundImage: "url('/img/signup_slide_bg.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "left",
    flex: "1 1 15%",
    backgroundColor: primaryColor[0],
    borderRadius: "3em 0 0 3em",
    "@media (max-width: 1199px)": {
      flex: "1 1 10%"
    },
    "@media (max-width: 1099px)": {
      flex: "1 1 5%"
    },
    "@media (max-width: 992px)": {
      display: "none"
    },
    overflow: "hidden"
  },
  slideOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: "3em 0 0 3em",
    backgroundColor: primaryColor[0]
  },
  formButton: {
    marginBottom: '1.5em'
  },
  backButton: {
    position: "absolute",
    marginTop: -2
  },
  planOptionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    minHeight: 200,
    marginBottom: "1.5em",
    borderRadius: "1em",
    overflow: "hidden",
    border: "2px solid " + primaryColor[0],
    boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)"
  },
  planOptionBody: {
    padding: "1em 1em 0 1em"
  },
  planOptionTitle: {
    fontWeight: "600",
    color: primaryColor[0],
    fontSize: "1.7em",
    marginBottom: "0.5em"
  },
  planOptionDescription: {
    color: primaryColor[3]
  },
  planOptionFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "1em",
    backgroundColor: "#F5F5F5",
    overflow: "hidden"
  },
  planOptionPrice: {
    fontWeight: "600",
    fontSize: "2em",
    marginTop: 5,
    color: primaryColor[0]
  },
  pricingCurrency: {
    fontSize: "0.75em",
    color: primaryColor[2]
  },
  pricingFrequency: {
    fontWeight: "400",
    fontSize: "0.4em",
    color: primaryColor[2]
  },
  addonContainer: {
    backgroundColor: whiteColor,
    width: "100%",
    padding: "1em",
    marginBottom: "1.5em",
    borderRadius: "1em",
    border: "2px solid " + primaryColor[4],
    boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)"
  },
  addonBody: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    whiteSpace: "nowrap"
  },
  addonTitle: {
    fontWeight: '700'
  },
  planSummaryContainer: {
    backgroundColor: "#F9F9F9",
    borderRadius: "1em",
    marginBottom: "1.5em",
    color: primaryColor[0],
    padding: "1.05em",
    fontWeight: "500",
    fontSize: "1em"
  },
  planSummaryItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "1em",
    fontSize: "1.1em"
  },
  planSummaryTotal: {
    borderTop: '2px dashed ' + primaryColor[3],
    paddingTop: '1em',
    color: primaryColor[0],
    marginBottom: 0
  },
  planSummaryLabel: {
    color: primaryColor[2]
  },
  cartContainer: {

  },
  cartItemContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flex: 1,
    backgroundColor: "#F9F9F9",
    borderRadius: "1em",
    marginBottom: "1.5em",
    padding: "0.5em"
  },
  cartItemImage: {
    position: 'relative',
    height: 100,
    width: 100,
    marginRight: '1em',
    minWidth: 100
  },
  cartItemTitle: {
    fontWeight: '600',
    fontSize: '1.06em',
    marginBottom: '0.1em'
  },
  cartItemDescription: {
    color: primaryColor[3],
    fontSize: '0.85em',
    marginBottom: '0.1em'
  },
  cartItemPrice: {
    fontWeight: '500',
    fontSize: '0.9em',
    color: primaryColor[2]
  },
  cardElementContainer: {
    backgroundColor: "#F9F9F9",
    padding: "1em",
    marginBottom: "1.5em",
    borderRadius: "1em"
  },
  cardElementTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontWeight: "600",
    color: primaryColor[0],
    fontSize: "1.1em",
    marginBottom: "1.2em"
  },
  cardElementLogo: {
    position: 'relative',
    height: 30,
    width: 30,
    marginLeft: '0.5em',
    minWidth: 30
  },
  cartItemHeader: {
    color: secondaryColor[0],
    fontWeight: '800',
    fontSize: '0.7em'
  },
  appliedPromoContainer: {
    minHeight: "3.5em",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    border: "1px solid " + primaryColor[4],
    borderRadius: "4px",
    marginBottom: "1.5em",
    alignItems: "center",
    padding: "0.45em"
  },
  appliedPromoTitle: {
    fontWeight: '600'
  },
  appliedPromoSubtitle: {
    fontSize: "0.7em",
    lineHeight: "1em",
    fontWeight: "500",
    color: primaryColor[3]
  },
  errorContainer: {
    display: 'flex',
    flex: 1,
    background: dangerColor[0],
    borderRadius: '0.5em',
    marginBottom: '1.5em',
    fontWeight: '500',
    color: whiteColor,
    padding: '0.8em'
  }
};

export default signupPageStyle;
