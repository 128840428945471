/*eslint-disable*/
import React from "react";
import Head from 'next/head';
import dynamic from 'next/dynamic';
// Framer motion
import { motion } from "framer-motion";
// @material-ui/core components
import withStyles from "@mui/styles/withStyles";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import KeyboardBackspace from "@mui/icons-material/KeyboardBackspace";
// Stripe
import { Elements } from '@stripe/react-stripe-js';
import { getStripe } from '../utilities/stripe';
// Intercom
import Intercom from '@intercom/messenger-js-sdk';
// Pages
import DetailsPage from "../pages-sections/signup/DetailsPage";
import PlanPage from "../pages-sections/signup/PlanPage";
import AddonsPage from "../pages-sections/signup/AddonsPage";
import PaymentPage from "../pages-sections/signup/PaymentPage";
// Presentation slide
import PresentationSlide from "../components/SignUp/PresentationSlide";
// Success overlay
const SuccessOverlay = dynamic(() => import('../components/SignUp/SuccessOverlay'));

import { primaryColor } from "styles/jss/nextjs-material-kit-pro";

import signupPageStyle from "styles/jss/nextjs-material-kit-pro/pages/signupPageStyle.js";

import Api from "api/index";

class SignUpPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stripePromise: null,
      formPage: -1,
      subTitleTransition: true,
      firstname: null,
      lastname: null,
      businessName: null,
      email: null,
      password: null,
      termsAgreed: false,
      planData: null,
      addonData: null,
      businessAccountTypeId: null,
      businessAccountAddonIds: [],
      createAccountLoading: false,
      formComplete: false
    };
  }

  componentDidMount() {
    this.setState({ formPage: 0 });
    // Init intercom
    Intercom({ app_id: 'u8n0vv7y' });
  }

  changePageViaProgressBar = (formPage) => {
    if(formPage > this.state.formPage) {
      return;
    }
    this.changePage(formPage);
  }

  changePage = async (formPage) => {
    if(formPage < 0 || formPage > 3 || this.state.formPage === formPage
      || this.state.createAccountLoading) {
      return;
    }
    if(formPage === 1) {
      // Load plan prices and availability
      this.getPlanData();
    }
    if(formPage === 2) {
      // Load addon prices and availability
      this.getAddonData();
    }
    if(formPage === 3 && !this.state.stripePromise) {
      this.setState({ stripePromise: getStripe() });
    }
    this.setState({ subTitleTransition: !this.state.subTitleTransition });
    setTimeout(() => {
      this.setState({ subTitleTransition: true, formPage });
    }, 400);
  }

  removeSubscription = () => {
    this.setState({
      formPage: 1,
      businessAccountTypeId: null,
      businessAccountAddonIds: []
    });
  }

  removeAddon = (businessAccountAddonId) => {
    if(this.state.businessAccountAddonIds.includes(businessAccountAddonId)) {
      let businessAccountAddonIds = [...this.state.businessAccountAddonIds];
      businessAccountAddonIds = businessAccountAddonIds.filter(x => x !== businessAccountAddonId);
      this.setState({ businessAccountAddonIds });
    }
  }

  getPlanData = async () => {
    if(!this.state.planData) {
      let response = await Api.getPlans();
      this.setState({ planData: response.data.planData });
    }
  }

  getAddonData = async () => {
    if(!this.state.addonData) {
      let response = await Api.getAddons();
      this.setState({ addonData: response.data.addonData });
    }
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  }

  renderSubTitle() {
    let subTitle;
    switch(this.state.formPage) {
      case -1:
      case 0:
        subTitle = "Let's get started";
        break;
      case 1:
        subTitle = "Select a plan";
        break;
      case 2:
        subTitle = "Add-ons";
        break;
      case 3:
        subTitle = "Payment details";
        break;
    }
    const words = [];
    for (const [, item] of subTitle.split(" ").entries()) {
      words.push(item.split(""));
    }
    words.map((word, wordIdx) => {
      if(wordIdx !== words.length - 1) {
        return word.push("\u00A0");
      } else {
        return word;
      }
    });
    return (
      <motion.div
        style={{ display: "flex", justifyContent: 'center' }}
        initial="hidden"
        animate={this.state.subTitleTransition ? "visible" : "hidden"}
        variants={{
          visible: {
            transition: {
              staggerChildren: 0.025
            }
          }
        }}
      >
        {words.map((word, index) => {
          return (
            // Wrap each word in the Wrapper component
            <div key={index}>
              {words[index].flat().map((element, index) => {
                return (
                  <span
                    style={{
                      overflow: "hidden",
                      display: "inline-block"
                    }}
                    key={index}
                  >
                    <motion.span
                      style={{ display: "inline-block", lineHeight: "30px" }}
                      variants={{
                        hidden: {
                          y: "200%",
                          color: primaryColor[0],
                          transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.85 }
                        },
                        visible: {
                          y: 0,
                          color: primaryColor[0],
                          transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.75 }
                        }
                      }}
                    >
                      {element}
                    </motion.span>
                  </span>
                );
              })}
            </div>
          );
        })}
      </motion.div>
    );
  }

  renderFormPage() {
    return (
      <div style={{ width: '100%' }}>
        <DetailsPage
          handleChange={this.handleChange}
          changePage={this.changePage}
          inTransition={this.state.subTitleTransition}
          isVisible={(this.state.formPage === 0 && this.state.subTitleTransition)}
          formState={this.state}
        />
        <PlanPage
          handleChange={this.handleChange}
          changePage={this.changePage}
          inTransition={this.state.subTitleTransition}
          isVisible={this.state.formPage === 1 && this.state.subTitleTransition}
          formState={this.state}
        />
        <AddonsPage
          handleChange={this.handleChange}
          changePage={this.changePage}
          inTransition={this.state.subTitleTransition}
          isVisible={this.state.formPage === 2}
          formState={this.state}
        />
        <Elements stripe={this.state.stripePromise}>
          <PaymentPage
            handleChange={this.handleChange}
            changePage={this.changePage}
            inTransition={this.state.subTitleTransition}
            isVisible={this.state.formPage === 3}
            formState={this.state}
            removeSubscription={this.removeSubscription}
            removeAddon={this.removeAddon}
          />
        </Elements>
      </div>
    )
  }

  renderBackButton(classes) {
    /*if(this.state.formPage === 0) {
      return null;
    }*/
    return (
      <motion.div
        animate={this.state.formPage > 0 ? "visible" : "hidden"}
        initial={{ opacity: 0 }}
        variants={{
          hidden: {
            opacity: 0,
            transition: { duration: 0.5 }
          },
          visible: {
            opacity: 1,
            transition: { duration: 0.5 }
          }
        }}
      >
        <IconButton
          className={classes.backButton}
          disabled={this.state.createAccountLoading}
          onClick={() => this.changePage(this.state.formPage - 1)}
        >
            <KeyboardBackspace/>
        </IconButton>
      </motion.div>
    );
  }
  
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Head>
          <title>Sign up — Styler</title>
          <meta
            name="description"
            content="Sign up to Styler! Let's get started on smoothing out the booking process with your own branded booking system and iOS/Android booking app."
            key="desc"
          />
          <meta property="og:title" content="Sign up — Styler" />
          <meta
            property="og:description"
            content="Let's get started on smoothing out the booking process with your own branded booking system and iOS/Android booking app."
          />
          <meta
            property="og:image"
            content="https://styler.digital/img/product-img/app-and-booking-plan.png"
          />
          <link rel="canonical" href="https://hello.styler.digital" />
        </Head>
        <div className={classes.pageContainer}>
          {this.state.formComplete && <SuccessOverlay firstname={this.state.firstname}/>}
          <div className={classes.formContainer}>
            <div className={classes.formHeader}>
            </div>
            <div className={classes.formBody}>
              <div className={classes.logoContainer}>
                <img src={"/img/styler_logo_secondary.svg"} alt="Styler Logo" />
              </div>
              <div className={classes.formTitle}>
                {this.renderBackButton(classes)}
                {this.renderSubTitle()}
              </div>
              <div className={classes.formProgressBarContainer}>
                <div style={{ flex: 1, cursor: 'pointer' }} onClick={() => this.changePageViaProgressBar(0) }>
                  <LinearProgress
                    variant="determinate"
                    value={100}
                    className={classes.formProgressBar}
                  />
                </div>
                <div style={{ flex: 1, cursor: 'pointer' }} onClick={() => this.changePageViaProgressBar(1) }>
                  <LinearProgress
                    variant="determinate"
                    value={this.state.formPage >= 1 ? 100 : 0}
                    className={classes.formProgressBar}
                  />
                </div>
                <div style={{ flex: 1, cursor: 'pointer' }} onClick={() => this.changePageViaProgressBar(2) }>
                  <LinearProgress
                    variant="determinate"
                    value={this.state.formPage >= 2 ? 100 : 0}
                    className={classes.formProgressBar}
                  />
                </div>
                <div style={{ flex: 1, cursor: 'pointer' }} onClick={() => this.changePageViaProgressBar(3) }>
                  <LinearProgress
                    variant="determinate"
                    value={this.state.formPage >= 3 ? 100 : 0}
                    className={classes.formProgressBar}
                  />
                </div>
              </div>
              {this.renderFormPage()}
            </div>
          </div>
          <PresentationSlide/>
        </div>
      </div>
    );
  }
}

export default withStyles(signupPageStyle)(SignUpPage);